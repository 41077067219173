import React from 'react'
import { graphql } from 'gatsby'
import styles from './index.module.css'
import LatestPosts from '../container/LatestPosts/LatestPosts'
import CategoryPosts from '../container/CategoryPosts/CategoryPosts'
import FeaturedPosts from '../container/FeaturedPosts/FeaturedPosts'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import Cookies from 'universal-cookie'

export default ({ data }) => {
  // console.log(data.site.siteMetadata)
  const cookies = new Cookies()

  var test = {
    pages: [
      {
        '/': {
          id: '/',
          voted: false,
        },
      },
    ],
  }

  // if (cookies.get('lengjaiCookie')) {
  //   console.log('you have existing cookies lol')
  // } else {
  //   cookies.set('lengjaiCookie', JSON.stringify(test), { path: '/', expires: new Date(2020,11,11) })
  //   var lengjaiCookie = cookies.get('lengjaiCookie')
  //   console.log('you dont have existing cookies so I create some for you')
  // }

  // console.log(cookies.get('lengjaiCookie'))
  console.log(data.PageViews)
  return (
    <Layout>
      <SEO
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
        image="/images/LENGJAI-black.jpg"
        pathname={data.site.siteMetadata.url}
      />
      <div className={styles.container}>
        <div className={styles.page}>
          <div className={styles.featured}>
            <FeaturedPosts node={data.allWordpressPostFeatured} />
          </div>
          <div className={styles.latest}>
            <LatestPosts pageViews={data.PageViews} node={data.Latest} />
          </div>
          <div className={styles.FashionPosts}>
            <CategoryPosts
              data={data.fashionPosts}
              pageViews={data.PageViews}
              name="Fashion"
            />
          </div>
          <div className={styles.CulturePosts}>
            <CategoryPosts
              data={data.culture}
              pageViews={data.PageViews}
              name="Culture"
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        titleTemplate
        description
        url
        image
      }
    }
    PageViews: allPageViews {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    Latest: allWordpressPost(sort: { fields: [date], order: DESC }, limit: 3) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000, maxHeight: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressPostFeatured: allWordpressPost(
      filter: { tags: { elemMatch: { name: { eq: "featured" } } } }
      sort: { fields: [date], order: ASC }
      limit: 3
    ) {
      edges {
        node {
          content
          title
          slug
          categories {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1500, maxHeight: 1000) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 600, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }

    fashionPosts: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "FASHION" } } } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    culture: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "CULTURE" } } } }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allWordpressPage(filter: { status: { eq: "publish" } }) {
      edges {
        node {
          id
          wordpress_id
          slug
          template
        }
      }
    }
    allWordpressCategory {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
