import React from 'react'
import styles from './featuredLengjai.module.css'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FaInstagram } from 'react-icons/fa'

const featuredLengjai = props => {
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Link
          className={styles.link}
          to={`/articles/` + props.data.slug}
        >
          <Img
            fluid={
              props.data.featured_media.localFile.childImageSharp.fluid
            }
            alt={''}
            className={styles.image}
          />
        </Link>
      </div>
      <div className={styles.subcontainer}>
        <a
          className={styles.instaContainer}
          href={'https://instagram.com/' + props.data.acf.instagram}
          aria-label="instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.instagram}>
            <FaInstagram />
          </div>
          <div className={styles.instaAt}>
            @{props.data.acf.instagram}
          </div>
        </a>
        <Link
          className={styles.link}
          to={`/articles/` + props.data.slug}
        >
          <div className={styles.name}>{props.data.acf.person}</div>
        </Link>
        <div className={styles.excerpt}>
          {props.data.acf.quicksummary}
        </div>
      </div>
    </div>
  )
}

export default featuredLengjai
