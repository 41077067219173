import React from 'react'
import styles from './categoryHeader.module.css'
import { Link } from 'gatsby'
import { FaArrowRight } from 'react-icons/fa'

const categoryHeader = props => {
  return (
    <div className={styles.container}>
      <Link className={styles.link} to={`/` + props.url.toLowerCase()}>
        <h2 className={styles.header}>{props.name} Posts</h2>
        <FaArrowRight size={30} className={styles.FaArrowRight} />
      </Link>
    </div>
  )
}

export default categoryHeader
