import React from 'react';
import styles from './FeaturedPosts.module.css';
import FeaturedPost from '../../components/Post/FeaturedPost/FeaturedPost';

const FeaturedPosts = (props) => {
  // console.log(props)
  return ( 
    <div className={styles.container}>
      {props.node.edges.map(({ node }) => (
        <div key={node.title} className={styles.FeaturedPost}><FeaturedPost node={node}/></div>  
      ))}
      
    </div> 
    );
}
 
export default FeaturedPosts;