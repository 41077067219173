import React, { Component } from 'react';
import Img from 'gatsby-image';
import styles from './FeaturedPost.module.css';
import { DOMParser } from 'xmldom'
import { Link } from '@reach/router';

class FeaturedPost extends Component {
    render() { 
        var dom = new DOMParser().parseFromString(`<div>${this.props.node.title}</div>`);
        var decodedString = dom.childNodes[0].textContent;
        return (  
            <Link
                className={styles.container}
                to={`/articles/${this.props.node.slug}`} >
                <div className={styles.category}>{this.props.node.categories[0].name}</div>
                <h2 className={styles.title} key={this.props.node.title}>{decodedString}</h2>
                <div className={styles.pictureWrap}>
                    <Img 
                        className={styles.picture} 
                        fluid={this.props.node.featured_media.localFile.childImageSharp.fluid} 
                        alt={''} 
                    />
                </div>
                <div className={styles.color} />
            </ Link>
         );
    }
}
 
export default FeaturedPost;