import React from 'react'
import styles from './FeaturedLengjais.module.css'
import FeaturedLengjai from '../../components/FeaturedLengjai/featuredLengjai'
import { StaticQuery, graphql, Link } from 'gatsby'

export default () => (
  <StaticQuery
    query={graphql`
      query {
        featuredLengjaisQuery: allWordpressPost(
          filter: { categories: { elemMatch: { name: { eq: "PEOPLE" } } } }
        ) {
          edges {
            node {
              slug
              content
              id
              title
              date(formatString: "MMMM DD, YYYY")
              author {
                name
              }
              acf {
                excerpt                
                instagram
                keywords                
                person
                quicksummary
                
              }
              featured_media {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, maxHeight: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className={styles.container}>
        <div className={styles.title}>Featured Lengjais</div>
        <div className={styles.featuredLengjai}>
          {data.featuredLengjaisQuery.edges.map(({ node }) => (
            <FeaturedLengjai key={node.title} data={node} />
          ))}
        </div>
      </div>
    )}
  />
)
