import styles from './latestPost.module.css'
import React, { Component } from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Emoji from 'react-emoji-render'
import { DOMParser } from 'xmldom'
import { CommentCount } from 'disqus-react'
import { FaComment } from 'react-icons/fa'
class Post extends Component {
  render() {
    
    var views = this.props.viewData[0]
      ? this.props.viewData[0].totalCount
      : 'New!'

    var dom = new DOMParser().parseFromString(
      `<div>${this.props.data.title}</div>`
    )
    var decodedString = dom.childNodes[0].textContent

    //THIS IS DIRTY AS FUCK!! Return the category for the routing...
    var temp = this.props.data.categories.map(swag => swag.name.toLowerCase())
    var category = temp[0]

    const disqusConfig = {
      url: `https://www.lengjai.com/articles/${this.props.data.slug}/`,
      identifier: `${this.props.data.id}`,
      title: `${this.props.data.title}`,
    }
    // console.log(this.props)
    return (
      <div className={styles.container}>
        <Link className={styles.Link} to={`/articles/${this.props.data.slug}`}>
          <div className={styles.pictureWrap}>
            <Img
              className={styles.picture}
              fluid={
                this.props.data.featured_media.localFile.childImageSharp.fluid
              }
              alt={''}
            />
          </div>
        </Link>
        <div className={styles.content}>
          <div className={styles.contentNested}>
            <div className={styles.categoryContainer}>
              {this.props.data.categories.map(swag => (
                <Link className={styles.category} to={category} key={swag.name}>
                  {swag.name}
                </Link>
              ))}
            </div>
            <h2 className={styles.headerText}>
              <Link
                className={styles.Link}
                to={`/articles/${this.props.data.slug}`}
              >
                {decodedString}
              </Link>
            </h2>
            <div
              className={styles.excerpt}
              dangerouslySetInnerHTML={{ __html: this.props.data.acf.excerpt }}
            />
            <Link
              className={styles.author}
              to={
                `/articles/` +
                this.props.data.author.name.replace(/\s/g, '').toLowerCase()
              }
            >
              {'By ' +
                this.props.data.author.name +
                ' / ' +
                this.props.data.date}
            </Link>
          </div>
          <div className={styles.viewsAndComments}>
            
              <div className={styles.fireIcon}>
                <Emoji text="🔥" />
              </div>
              <div className={styles.fireCount}>{views}</div>
            
            <Link
              className={styles.commentsContainer}
              to={`/articles/` + this.props.data.slug}
            >
              <div className={styles.commentIcon}>
                <FaComment />
              </div>
              <div className={styles.commentCount}>
                <CommentCount shortname="lengjai-com" config={disqusConfig} />
              </div>
              Comments
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Post
