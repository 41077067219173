import React from 'react'
import Post from '../../components/Post/LatestPost/latestPost'
import styles from './LatestPosts.module.css'
import CategoryHeader from '../../components/CategoryHeader/categoryHeader'
import FeaturedLengjais from '../FeaturedLengjai/FeaturedLengjais'

const LatestPosts = props => {
  //   console.log(props)

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <CategoryHeader name="Latest" url="/" />
      </div>
      <div className={styles.postsContainer}>
        {props.node.edges.map(({ node }) => {
          var filtered = []
          for (var i = 0; i < props.pageViews.edges.length; i++) {
            if (
              props.pageViews.edges[i].node.id ===
              '/articles/' + node.slug
            ) {
              filtered.push(props.pageViews.edges[i].node)
            }
          }
          return (
            <div className={styles.post} key={node.title}>
              <Post viewData={filtered} data={node} />
            </div>
          )
        })}
      </div>
      <FeaturedLengjais />
    </div>
  )
}

export default LatestPosts
