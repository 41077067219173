import React, { Component } from 'react'
import Post from '../../components/Post/CategoryPost/CategoryPost'
import styles from './CategoryPosts.module.css'
import CategoryHeader from '../../components/CategoryHeader/categoryHeader'

class CategoryPosts extends Component {
  state = {}
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.categoryHeader}>
          <CategoryHeader name={this.props.name} url={this.props.name} />
        </div>
        <div className={styles.posts}>
          {this.props.data.edges.map(({ node }) => {
            var filtered = []
            for (var i = 0; i < this.props.pageViews.edges.length; i++) {
              if (
                this.props.pageViews.edges[i].node.id ===
                '/articles/' + node.slug
              ) {
                filtered.push(this.props.pageViews.edges[i].node)
              }
            }
            return (
              <div className={styles.post} key={node.title}>
                <Post viewData={filtered} data={node} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default CategoryPosts
